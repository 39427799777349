import React, {Component} from "react";
class Campagne extends Component {
    render() {
        return ( 
        <div style={{
            backgroundImage: 'url(./UIcampaign.png)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100vh'
          }}>            
        </div>)
    }
}

export default Campagne;